import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  Facebook,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Nora Bite"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Nora Bite"
        videoUrl="https://vimeo.com/498099398/6c79f75b2e"
      />
      <Width pt="12.9rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"curt"}
                fluid={data.noraBiteImage.childImageSharp.fluid}
              />
            </Box>
          </Box>
          <Box width={["100%", null, 8 / 12, null]}>
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Nora Bite
            </Text>
            <Text level="1" mb="1.2rem">
              Accomplished Jazz Guitarist
            </Text>
            <Box>
              <Link to="https://www.instagram.com/norabiteguitar/" as="a">
                <Instagram mr="1.2rem" />
              </Link>
              <Link to="https://www.facebook.com/norabiteguitar/" as="a">
                <Facebook />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Nora Bite is a jazz guitarist, educator and composer from Latvia,
              based in London. She has been a guitarist for{" "}
              <strong>20+ years</strong>, and has been a part of{" "}
              <strong>100+ bands</strong> and projects in Latvia, the UK,
              Portugal, Italy, Boston (USA), Spain, Belgium, Egypt, Spain,
              Lithuania and Estonia, and has taught 200+ guitar students over
              many years in London, UK, Latvia and internationally over
              Skype/Facetime. She has been an active member of the London jazz
              community since 2010, running one of the best weekly jazz jams for
              4 years, composing for and leading her modern jazz trio, working
              as a session musician as well as serving a global musician
              community on Instagram. One of the many artists Nora has had the
              opportunity to share the stage with includes the great, late{" "}
              <strong>Amy Winehouse</strong>.
            </Text>
            <Text level="2">
              Nora is always curious about exploring new styles of music. She is
              comfortable with many different guitar techniques and Musical
              styles including jazz standards (swing), bebop, fusion, modern
              jazz, Bossa nova, latin music, flamenco (in the beginning of her
              musical career), blues, funk, soul, pop, rock, classical guitar,
              finger style chord melody, African guitar, jazz comping in duos
              (combining walking bass lines with chords), playing with looper
              and bass guitar. Nora often plays jazz standard vocal and
              instrumental duos as accompanist, as well as trios and duos as a
              soloist.
            </Text>
            <Text level="2">
              Nora has played in most of the important jazz clubs in London,
              such as{" "}
              <strong>
                “Ronnie Scotts”, “Vortex”, “606”, “Charlie Wrights”
              </strong>{" "}
              and others together with various artists and as well as a leader
              of multiple jazz projects.
            </Text>
            <Text level="2">
              Before moving to London, back in Latvia, she was leading her
              modern jazz quartet, and playing in many other projects, including
              big bands, jazz bands, blues-rock, funk and pop bands, playing in
              restaurants as a flamenco and jazz solo fingerstyle guitarist, as
              well as being part of international jazz concert management
              organizations. She was also giving regular guitar workshops in{" "}
              <strong>”Madona Guitar sessions”</strong>, that happens once a
              year in the Latvian town Madona.
            </Text>
          </Box>
        </Box>
      </Width>
      <MentorFooter data={data} name="Nora Bite" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    noraBiteImage: file(
      relativePath: { eq: "noraBite-L.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
